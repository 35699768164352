<template>
	<div class="full-height flex-column">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div class="save">
				<button
					@click="postSave"
					class="btn_save"
					:disabled="is_save"
				>{{  $language.common.complete }}</button>
			</div>
		</div>

		<div class="subindex_item full-height">
			<div class="container full-height pa-20">
				<div class="input_area mt-50">
					<div class="bright_input">
						<label for="cartelDesc">{{  $language.notice.name }} <!-- 게시판 이름 --></label>
						<div class="input_utill">
							<input
								v-model="item.cartl_vote_board_name"
								type="text" id="cartelDesc" :placeholder="$language.notice.name_require" maxlength="20"
							>
							<div class="input_utill_area">
								<div
									v-if="item.cartl_vote_board_name"
									class="btn_del"
								>
									<button
										@click="item.cartl_vote_board_name = ''"
										class="active"
									></button>
								</div>
								<span class="input_text_count">{{ item.cartl_vote_board_name.length }}/20</span>
							</div>
						</div>
					</div>
					<div class="input_area mt-20">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.voting.title_voting_board_manager_permission }} <!-- 원영자 권한 설정 --></div>
							<p class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item.admin_authority_settup_fg == 'Y'}"
									@click="item.admin_authority_settup_fg = item.admin_authority_settup_fg == 'Y' ? 'N' : 'Y' "
								></span>
							</p>
						</div>
						<p class="input_desc pb-0">
							{{  $language.voting.txt_voting_board_manager_permission }} <!-- 운영자에게 카르텔 투표등록, 수정 권한을 줍니다 -->
						</p>
					</div>
					<div class="input_area mt-20">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.voting.title_voting_board_open }} <!-- 보팅 게시판 공개 설정 --></div>
							<p class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item.vote_release_settup_fg == 'Y'}"
									@click="item.vote_release_settup_fg = item.vote_release_settup_fg == 'Y' ? 'N' : 'Y' "
								></span>
							</p>
						</div>
						<p class="input_desc pb-0">
							{{  $language.voting.txt_voting_board_open }} <!-- 카르텔에 가인됩 회원에게만 게시판을 공개합니다 -->
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelVotingBoardAdd'
		, props: ['user']
		, data: function(){
			return {
				program: {
					title: this.$language.voting.title_voting_board_add
					, name: this.$language.voting.title_voting_board_add
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, item: {
					cartl_vote_board_name: ''
					, admin_authority_settup_fg: 'Y'
					, vote_release_settup_fg: 'Y'
				}
				, v_id: this.$route.params.v_id
			}
		}
		, computed: {
			is_save: function(){
				let t = true
				if(this.item.cartl_vote_board_name){
					t = false
				}
				return t
			}
		}
		, methods: {
			postSave: async function(){
				try{
					this.$bus.$emit('on', true)
					let url = this.$api_url.api_path.post_voting_board
					if(this.v_id){
						url = this.$api_url.api_path.put_voting_board
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_name: this.item.cartl_vote_board_name
							, admin_authority_settup_fg: this.item.admin_authority_settup_fg
							, vote_release_settup_fg: this.item.vote_release_settup_fg
							, cartl_vote_board_number: this.$route.params.v_id ? this.$route.params.v_id : this.item.board_number
						}
						, type: true
					})
					if(result.success){
						if(this.v_id){
							await this.getData()
							this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						}else{
							this.$emit('goBack')
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_board
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			if(this.v_id){
				this.getData()
			}
		}
	}
</script>